import React, { Component } from 'react';

class Banner extends Component {
    render(){
        return (
            <section className="banner">
                <div className="container">
                    <div className="inner-container">
                        <h1>Frontend developer</h1>
                    </div>
                    <div className="subtitle"><p>I'm Arol, a freelance fontend developer and UX/UI designer. I build accessible, responsive and faster websites for agencies and startups.</p></div>
                </div>
            </section>
        )
    }
}

export default Banner;