import React, { Component } from 'react';

class Portfolio extends Component {
    render() {
        return (
            <section id="projects" className="card-container">
              {this.props.project.map((project) =>
              <article className="card">
                  <div className="card-image">
                      <img src={project.image} alt={project.title}/>
                  </div>
                  <div className="card-text">
                      <h3>{project.title}</h3>
                      <p>{project.text}</p>
                  </div>
              </article>
              )}
          </section>
        );
    }
}

export default Portfolio;

