import React, { Component } from 'react';

class ContactPlain extends Component {
    render() {
        return (
            <section className="container">
                <div className="row">
                    <div className="col"><h2>Contact me</h2></div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>If you want to hire my services, please contact me via arol.figueroa@gmail.com. You also can find me on <a href="https://github.com/aroldev" title="Find me on Github">Github</a> or <a
                                href="https://arolme.slack.com" title="Find me on Slack">Slack</a>. </p>
                    </div>
                </div>
            </section>
    )
    }
}
export default ContactPlain;