import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
        <>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <a className="footer-link" href="https://www.arol.me" title="Front end developer based in Edinburgh">arol.me</a>
                            </div>
                            <div className="col">
                                <div className="social-links">
                                    <a href="https://www.twitter.com/mrcatcool">
                                        <span className="sr-only">Follow me on Twitter</span>
                                        <svg width="93" height="76" viewBox="0 0 93 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M92.9999 9.59424C89.5999 11.0942 85.9998 12.0942 82.1998 12.5942C86.0998 10.2942 89.0999 6.59434 90.4999 2.19434C86.8999 4.39434 82.7999 5.89419 78.4999 6.79419C75.0999 3.09419 70.1998 0.794189 64.6998 0.794189C54.2998 0.794189 45.7999 9.29434 45.7999 19.6943C45.7999 21.1943 45.9999 22.5941 46.2999 23.9941C30.5999 23.1941 16.6999 15.6942 7.3999 4.29419C5.7999 7.09419 4.79993 10.2942 4.79993 13.7942C4.79993 20.2942 8.09983 26.0941 13.1998 29.4941C10.0998 29.3941 7.19983 28.5942 4.69983 27.0942C4.69983 27.1942 4.69983 27.2942 4.69983 27.2942C4.69983 36.3942 11.1999 44.0942 19.7999 45.7942C18.1999 46.1942 16.4999 46.4941 14.7999 46.4941C13.5999 46.4941 12.3998 46.3943 11.1998 46.1943C13.5998 53.6943 20.5999 59.1942 28.7999 59.2942C22.2999 64.3942 14.1999 67.3943 5.3999 67.3943C3.8999 67.3943 2.3999 67.2942 0.899902 67.0942C9.2999 72.4942 19.1999 75.5942 29.7999 75.5942C64.4999 75.5942 83.4999 46.7943 83.4999 21.8943C83.4999 21.0943 83.4999 20.2941 83.3999 19.4941C87.2999 16.6941 90.4999 13.3942 92.9999 9.59424Z" fill="#34485D"/>
                                        </svg>
                                    </a>
                                    <a href="https://github.com/aroldev">
                                        <span className="sr-only">Link to my Github account</span>
                                        <svg width="93" height="79" viewBox="0 0 93 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M62.8998 45.5942C59.2998 45.5942 56.3998 49.5942 56.3998 54.5942C56.3998 59.5942 59.2998 63.5942 62.8998 63.5942C66.4998 63.5942 69.3998 59.5942 69.3998 54.5942C69.3998 49.6942 66.4998 45.5942 62.8998 45.5942ZM85.4998 22.1943C86.1998 20.3943 86.2998 9.99424 82.2998 0.0942383C82.2998 0.0942383 73.1998 1.09414 59.4998 10.4941C56.5998 9.69414 51.7998 9.29419 46.8998 9.29419C41.9998 9.29419 37.1998 9.69414 34.2998 10.4941C20.5998 1.09414 11.4998 0.0942383 11.4998 0.0942383C7.5998 9.99424 7.5998 20.3943 8.2998 22.1943C3.6998 27.1943 0.799805 33.2942 0.799805 41.5942C0.799805 77.4942 30.5998 78.0942 38.0998 78.0942C39.7998 78.0942 43.1998 78.0942 46.7998 78.0942C50.3998 78.0942 53.7998 78.0942 55.4998 78.0942C62.9998 78.0942 92.7998 77.4942 92.7998 41.5942C92.9998 33.2942 90.1998 27.2943 85.4998 22.1943ZM47.0998 73.6943H46.7998C27.8998 73.6943 13.2998 71.3942 13.2998 53.0942C13.2998 48.6942 14.7998 44.5942 18.4998 41.2942C24.5998 35.6942 34.9998 38.5942 46.7998 38.5942H46.8998H46.9998C58.7998 38.5942 69.1998 35.5942 75.2998 41.2942C78.9998 44.6942 80.4998 48.7942 80.4998 53.0942C80.6998 71.3942 65.9998 73.6943 47.0998 73.6943ZM31.0998 45.5942C27.4998 45.5942 24.5998 49.5942 24.5998 54.5942C24.5998 59.5942 27.4998 63.5942 31.0998 63.5942C34.6998 63.5942 37.5998 59.5942 37.5998 54.5942C37.5998 49.6942 34.6998 45.5942 31.0998 45.5942Z" fill="#34485D"/>
                                        </svg>

                                    </a>
                                    <a href="https://arolme.slack.com">
                                        <span className="sr-only">Link to my Slack account</span>
                                        <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M26.4 78.2C26.4 85.3 20.6 91.1 13.5 91.1C6.4 91.1 0.599998 85.3 0.599998 78.2C0.599998 71.1 6.4 65.3 13.5 65.3H26.4V78.2Z" fill="#34485D"/>
                                            <path d="M32.9 78.2C32.9 71.1 38.7 65.3 45.8 65.3C52.9 65.3 58.7 71.1 58.7 78.2V110.5C58.7 117.6 52.9 123.4 45.8 123.4C38.7 123.4 32.9 117.6 32.9 110.5V78.2Z" fill="#34485D"/>
                                            <path d="M45.8 26.4C38.7 26.4 32.9 20.6 32.9 13.5C32.9 6.40001 38.7 0.600006 45.8 0.600006C52.9 0.600006 58.7 6.40001 58.7 13.5V26.4H45.8Z" fill="#34485D"/>
                                            <path d="M45.8 32.9C52.9 32.9 58.7 38.7 58.7 45.8C58.7 52.9 52.9 58.7 45.8 58.7H13.5C6.4 58.7 0.599998 52.9 0.599998 45.8C0.599998 38.7 6.4 32.9 13.5 32.9H45.8Z" fill="#34485D"/>
                                            <path d="M97.6 45.8C97.6 38.7 103.4 32.9 110.5 32.9C117.6 32.9 123.4 38.7 123.4 45.8C123.4 52.9 117.6 58.7 110.5 58.7H97.6V45.8Z" fill="#34485D"/>
                                            <path d="M91.1 45.8C91.1 52.9 85.3 58.7 78.2 58.7C71.1 58.7 65.3 52.9 65.3 45.8V13.5C65.3 6.40001 71.1 0.600006 78.2 0.600006C85.3 0.600006 91.1 6.40001 91.1 13.5V45.8Z" fill="#34485D"/>
                                            <path d="M78.2 97.6C85.3 97.6 91.1 103.4 91.1 110.5C91.1 117.6 85.3 123.4 78.2 123.4C71.1 123.4 65.3 117.6 65.3 110.5V97.6H78.2Z" fill="#34485D"/>
                                            <path d="M78.2 91.1C71.1 91.1 65.3 85.3 65.3 78.2C65.3 71.1 71.1 65.3 78.2 65.3H110.5C117.6 65.3 123.4 71.1 123.4 78.2C123.4 85.3 117.6 91.1 110.5 91.1H78.2Z" fill="#34485D"/>
                                        </svg>


                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>

        )
    }
}

export default Footer;