import React, { Component } from 'react';
class Header extends Component {
    render() {
        return (
            <div>
            <header>
                <div className="logo">
                    <a href="/"><strong>arol</strong><span>.</span>me</a>
                </div>
                <nav className="menu">
                    <a href="#projects">Projects</a>
                </nav>
            </header>
                </div>
    )
    }
}

export default Header;