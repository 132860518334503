export const projectsdata =[
    {
        image:"./images/portfolio/police.jpg",
        title: "Join the Police",
        text: "Accessibility and Frontend development",
        url: "https://www.joiningthepolice.co.uk/"
    },
    {
        image:"./images/portfolio/ebdv.jpg",
        title: "El Blog de Viajes",
        text: "Wordpress theme and speed improvement",
        url: "https://elblogdeviajes.com/"
    },

    {
        image:"./images/portfolio/esms.jpg",
        title:"ESMS School",
        text: "Drupal template and UX improvements",
        url:"https://www.esms.org.uk"
    },
    {
        image:"./images/portfolio/ggn.jpg",
        title: "Glengoyne Distillery",
        text: "Drupal and Magento template",
        url: "https://www.glengoyne.com/"
    },
    {
        image:"./images/portfolio/whiskyfr.jpg",
        title: "Whisky Shop France",
        text: "Magento template and JS development",
        url:"https://www.whiskyshop.fr/"
    },
    {
        image:"./images/portfolio/reliance.jpg",
        title: "Reliance Bank",
        text: "Drupal template and Accessibility support",
        url:"https://www.reliancebankltd.com/"
    },
];