import React, { Component } from 'react';

class Services extends Component {
    render() {
        return (
            <div className="card-container card-container__text container">
                <div className="row">
                    <div className="col">
                        <h2 className="text-center">What I do</h2>
                    </div>
                </div>
                <div className="row">
                    {this.props.services.map((services) =>
                        <div className="col">
                            <div className="card">
                                <div className="services-title"><h3>{services.titledo}</h3></div>
                                <div className="services-description"><p>{services.textdo}</p></div>
                            </div>
                        </div>

                    )}
                </div>
            </div>
        );
    }
}

export default Services;

