export const servicesdata = [
    {
        titledo: "Frontend",
        textdo: "I'm happy working with Javascript, React, HTML5, CSS3, Sass, GIT. I focus all my developments in mobile first. I also work with website animations."
    },
    {
        titledo: "Accessibility",
        textdo: "I audit and deliver the most complete result about how to improve a website to be accessible. I have experience working for public and private sectors."
    },
    {
        titledo: "UX/UI design",
        textdo: "Conceptual design and analysis based on User Experience. On my designs I put special attention on usability and accessibility."
    },
];
