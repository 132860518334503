import React, { Component } from 'react';
import Portfolio from './Portfolio'
import {projectsdata} from '../data/projectsData';

class Projects extends Component {
    render (){
        return (
            <Portfolio project={projectsdata}/>
        )
    }
}

export default Projects;