import React, { Component } from 'react';
import Services from './Services';
import {servicesdata} from '../data/servicesData';

class WhatDo extends Component {
    render (){
        return (
            <Services services={servicesdata}/>
        )
    }
}

export default WhatDo;