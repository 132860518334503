import React from 'react';
import './App.scss';
import ReactGA from 'react-ga';
import Header from './components/Header';
import Banner from './components/elements/Banner'
import Projects from './components/Projects'
import Footer from './components/Footer'
import WhatDo from './components/WhatDo'
import About from './components/About'
import ContactPlain from './components/Contact'

function App() {
  return (
    <div className="App">
      <Header/>
      <Banner/>
        <Projects/>
        <WhatDo/>
        <About/>
        <ContactPlain/>
        <Footer/>
    </div>
  );
}
// function initializeReactGA() {
    ReactGA.initialize('UA-109380191-1');
    ReactGA.pageview('/homepage');
// }
export default App;
