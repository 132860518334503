import React, { Component } from 'react';
import arol from '../images/arol.jpg';

class About extends Component {
    render(){
        return (
            <section className="container image-text">
                <div className="row">
                    <div className="col">
                        <h2>Who I am</h2>
                    </div>
                </div>
                <div className="row align-item-center">
                    <div className="col-50 text">
                        <p>
                            I'm Frontend developer, UX designer and Accessibility Specialist currently working in
                            Edinburgh and globally </p>
                        <p>
                            I specialise in responsive web developments using SASS, jQuery, React, PHP, Gulp,
                            Webpack, Bootstrap and my own HTML framework.
                            </p>
                        <p>
                            I use tools such as Figma, Slack, Git (Bitbucket and Github), Jira and more.
                        </p>
                        <p>
                            I also build themes for Magento, Drupal and Wordpress. If you want to hire me, please, <a href="#contact">get in touch</a>
                        </p>
                    </div>
                    <div className="col-50">
                        <figure className="image">
                            <img src={arol} alt="Arol face looking to the camera"/>
                            <figurecaption >Arol</figurecaption>
                        </figure>
                    </div>
                </div>
            </section>
        )
    }
}

export default About;